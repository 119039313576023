<style lang="scss">
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 75%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
  .modal-content {
    min-height: 46rem !important;
  }
}
</style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="xl"
    v-model="showModal"
    :title="'Daily Mixing Report'"
  >
    <div class="row" v-if="loadingData">
      <div class="col-12 text-center" style="padding: 10px">
        <loadingSpinner></loadingSpinner>
        <br />
        <h6>Please Wait, we are get the details.</h6>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-12">
        <div class="row">
          <div class="col-6">
            <label for="">Date:</label>
            <label for="" class="detail ml-2">{{
              mixingReportDetail.date
            }}</label>
          </div>
          <div class="col-6">
            <button @click="print" class="float-right btn btn-info btn-sm">
              Print
            </button>
          </div>

          <!-- <li>
                    <label for="">Date of Birth: </label>
                    <label for="" class="detail">{{
                      appointmentData.dob
                    }}</label>
                  </li> -->
        </div>

        <div class="log mt-5" style="min-height: 27rem !important">
          <div class="row">
            <div class="col-md-12" style="background: white">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th style="width: 10px">Variety</th>
                    <th scope="col">Flour</th>
                    <th scope="col">Corn Flour</th>
                    <th scope="col">Veg Fat</th>
                    <th scope="col">Sugar</th>
                    <th scope="col">Invert Syrup</th>
                    <th scope="col">Malt Ext.</th>
                    <th scope="col">Salt</th>
                    <th scope="col">GMS</th>
                    <th scope="col">Lecithin</th>
                    <th scope="col">Ammonia</th>
                    <th scope="col">Soda</th>
                    <th scope="col">S.M.S.</th>
                    <th scope="col">Yeast</th>
                    <th scope="col">Papain</th>
                    <th scope="col">Cocokita Oil</th>
                    <th scope="col">Soya</th>
                    <th scope="col">Vitamin</th>
                    <th scope="col">Cooca Powd.</th>
                    <th scope="col">Coconut Powd.</th>
                    <th scope="col">Bran</th>
                    <th scope="col">Dough Serap</th>
                    <th scope="col">Biscuit Pdr.</th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    !loadingData &&
                    mixingReportDetail.over_report_detail.length > 0
                  "
                >
                  <tr
                    style="width: 100px !important"
                    v-for="(
                      item, index
                    ) in mixingReportDetail.over_report_detail"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td style="width: 10px">{{ item.variety }}</td>
                    <td>{{ item.flour }}</td>
                    <td>{{ item.corn_flour }}</td>
                    <td>{{ item.veg_fat }}</td>
                    <td>{{ item.sugar }}</td>
                    <td>{{ item.invert_syrup }}</td>
                    <td>{{ item.malt_ext }}</td>
                    <td>{{ item.salt }}</td>
                    <td>{{ item.gms }}</td>
                    <td>{{ item.lecithin }}</td>
                    <td>{{ item.ammonia }}</td>
                    <td>{{ item.soda }}</td>
                    <td>{{ item.sms }}</td>
                    <td>{{ item.yeast }}</td>
                    <td>{{ item.papain }}</td>
                    <td>{{ item.cocokita_oil }}</td>
                    <td>{{ item.soya }}</td>
                    <td>{{ item.vitamin }}</td>
                    <td>{{ item.cocoa_powder }}</td>
                    <td>{{ item.coconut_powder }}</td>
                    <td>{{ item.bran }}</td>
                    <td>{{ item.dough_serap }}</td>
                    <td>{{ item.biscuit_powder }}</td>
                  </tr>
                </tbody>

                <tbody
                  v-if="
                    !loadingData &&
                    mixingReportDetail.over_report_detail.length < 1
                  "
                >
                  <tr>
                    <td colspan="24" class="text-center">No record(s) found</td>
                  </tr>
                </tbody>

                <tbody v-if="loadingData">
                  <tr>
                    <th class="text-center" colspan="24">
                      <loadingSpinner></loadingSpinner>
                    </th>
                  </tr>
                </tbody>
                <!----><!---->
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row mt-2"
      id="printme"
      style="visibility: hidden; display: none"
    >
      <div class="col-md-12">
        <div class="row">
          <div class="col-12 text-center">
            <h2 style="text-align: center">
              {{ currentUser.organization.name }}
            </h2>
            <!-- <h2 style="text-align: center">
              Shree Pashupati Biscuit Industries (Pvt.) Ltd.
            </h2>
            <h4 style="text-align: center">DUHABI, SUNSARI</h4> -->
            <h3 style="text-align: center">Daily Mixing Report</h3>
          </div>
        </div>
        <div class="row">
          <div>
            <span> Date: {{ mixingReportDetail.date }}</span>
          </div>
        </div>

        <div>
          <div class="row" id="iframe-print-content">
            <div class="col-md-12">
              <table
                style="
                  table-layout: fixed !important;
                  font-size: 12px;
                  margin-top: 5px;
                  border: 1px solid black;
                  border-collapse: collapse;
                  width: 1400px;
                "
              >
                <thead>
                  <tr>
                    <th
                      style="
                        border: 1px solid black;
                        border-collapse: collapse;
                        width: 15px;
                      "
                    >
                      SN
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Variety
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Flour
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Corn Flour
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Veg Fat
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Sugar
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Invert Sugar
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Malt Ext.
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Salt
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      GMS
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Lecithin
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Ammonia
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Soda
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      S.M.S.
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Yeast
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Papain
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Cocokita Oil
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Soya
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Vitamin
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Cooca Powd.
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Coconut Powd.
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Bran
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Dough Serap
                    </th>
                    <th
                      style="
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;

                        word-wrap: break-word;
                      "
                    >
                      Biscuit Pdr.
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    !loadingData &&
                    mixingReportDetail.over_report_detail.length > 0
                  "
                >
                  <tr
                    style="width: 100px !important"
                    v-for="(
                      item, index
                    ) in mixingReportDetail.over_report_detail"
                    :key="index"
                  >
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ index + 1 }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.variety }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.flour }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.corn_flour }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.veg_fat }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.sugar }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.invert_syrup }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.malt_ext }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.salt }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.gms }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.lecithin }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.ammonia }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.soda }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.sms }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.yeast }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.papain }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.cocokita_oil }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.soya }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.vitamin }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.cocoa_powder }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.coconut_powder }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.bran }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.dough_serap }}
                    </td>
                    <td
                      style="
                        padding-top: 5px;
                        padding-left: 2px;
                        padding-bottom: 5px;
                        border: 1px solid black;
                        border-collapse: collapse;
                        word-wrap: break-word;
                      "
                    >
                      {{ item.biscuit_powder }}
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="mixingReportDetail.over_report_detail.length < 1">
                  <tr>
                    <td style="text-align: center" colspan="24">No record(s) found</td>
                  </tr>
                </tbody>

                <tbody v-if="loadingData">
                  <tr>
                    <th class="text-center" colspan="24">
                      <loadingSpinner></loadingSpinner>
                    </th>
                  </tr>
                </tbody>
                <!----><!---->
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!hasData && !loadingData">
      <div class="col-md-12 col-12" style="padding: 10px">
        <h5>Sorry, we didn't found details</h5>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import loadingSpinner from "@/components/frontend/spinner.vue";

import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    loadingData: {
      type: Boolean,
      default: false,
    },
    hasData: {
      type: Boolean,
      default: false,
    },
    indexNum: {
      type: Number,
      default: -1,
    },
    mixingReportDetail: {
      type: Object,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },

  components: {
    loadingSpinner,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    document.body.addEventListener("keyup", (e) => {
      if (e.keyCode == 27) {
        this.$emit("closemodal");
      }
    });
  },
  methods: {
    close() {
      this.$emit("closemodal");
    },
    print() {
      var contents = document.getElementById("printme").innerHTML;
      var frame1 = document.createElement("iframe");
      frame1.name = "frame1";
      frame1.style.position = "absolute";
      frame1.style.top = "-1000000px";
      document.body.appendChild(frame1);
      var frameDoc = frame1.contentWindow
        ? frame1.contentWindow
        : frame1.contentDocument.document
        ? frame1.contentDocument.document
        : frame1.contentDocument;
      frameDoc.document.open();
      frameDoc.document.write(`<html><head>`);
      frameDoc.document.write("</head><body>");
      frameDoc.document.write(contents);
      frameDoc.document.write("</body></html>");
      frameDoc.document.close();
      var css = "@page { size: A4 landscape; }",
        head =
          frameDoc.document.head || document.getElementsByTagName("head")[0],
        style = frameDoc.document.createElement("style");

      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(frameDoc.document.createTextNode(css));
      }

      head.appendChild(style);
      setTimeout(function () {
        window.frames["frame1"].focus();
        window.frames["frame1"].print();
        document.body.removeChild(frame1);
      }, 500);
      return false;
    },
  },

  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function (value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      return moment(String(fullDate)).format("h:mm a");
    },
    sDateFormate: function (date) {
      if (date == null) {
        return;
      }
      return moment(String(date)).format("dddd, MMMM DD, YYYY");
    },
  },
};
</script>
